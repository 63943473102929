<template>
  <v-main>
    <HeaderBar />
    <div class="successful">
      <!-- eslint-disable-next-line max-len -->
      <lottie-player class="lottie" src="./success.json" background="transparent" speed="2"></lottie-player>
      <h2 style="text-align: center; font-size: 3em;">{{ successText }}</h2>
    </div>
  </v-main>
</template>

<script>
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import HeaderBar from './HeaderBar.vue';

export default {
  name: 'GreatSuccess',
  components: {
    HeaderBar,
  },
  data() {
    return {
      temp: null,
    };
  },
  computed: {
    successText() {
      return this.$store.state.successText;
    },
  },
  created() {
    document.title = 'LandrumHR';
  },
  mounted() {
    gsap.registerPlugin(CustomEase);

    // eslint-disable-next-line new-cap
    const tl = new gsap.timeline({ defaults: { ease: 'circ.easeOut' } });
    // eslint-disable-next-line no-unused-vars
    const player = document.querySelector('.lottie');

    tl.paused(true);
    tl.to('.successful', { opacity: 1, duration: 2, zIndex: 3 }, '+=1');

    tl.play();
    setTimeout(() => {
      player.play();
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
.successful {
  position: absolute;
  width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
</style>
