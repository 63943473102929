<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>
// import HomePage from './components/HomePage.vue';

export default {
  name: 'App',

  // components: {
  //   HomePage,
  // },
  created() {
    // eslint-disable-next-line no-underscore-dangle
    window._paq.push(['setDomains', ['*.jobs.landrumhr.com', '*.apply-jobs.landrumhr.com', '*.apply.landrumjobs.com', '*.landrumjobs.com']]);
    // eslint-disable-next-line no-underscore-dangle
    window._paq.push(['enableCrossDomainLinking']);
  },
  data: () => ({
    // todo: add matomo
  }),
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/* Global styles */
html {
  overflow-y: auto;
}
.v-btn {
  text-transform: none !important;
}
#app {
  font-family: 'Open Sans', sans-serif;
  background-color: #F3F3F3;
}
/* Google Maps stylings */
.custom-map-control-button {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
    margin: 10px;
    padding: 0 0.5em;
    font: 400 18px Roboto, Arial, sans-serif;
    overflow: hidden;
    height: 40px;
    cursor: pointer;
}
.custom-map-control-button:hover {
    background: #ebebeb;
}
/* Bullhorn Jobs html styles */
.resume-html > .v-card__text {
  font-family: Roboto,sans-serif;
  color: #333;
  word-wrap: break-word;
  line-height: normal;
  font-size: 13px;
}
.resume-html > .v-card__text > h1 {
  margin: 17.4px 0;
}
.resume-html > .v-card__text > h2 {
  margin: 16.2px 0;
}
.resume-html > .v-card__text > ul {
  padding-left: 40px;
  margin: 13px 0;
}
.resume-html > .v-card__text > p {
  margin: 1em 0;
}
.resume-html > .v-card__text strong {
  font-weight: 700;
}
#colorMe > .v-ripple__container {
  color: #1444FF !important;
}
/* Fix text pointer when using menu slot */
div#categories > .v-text-field > .v-input__control > .v-input__slot {
  cursor: default !important;
}
div#categories > .v-text-field > .v-input__control > .v-input__slot > .v-text-field {
  cursor: text !important;
}
div#mileage > .v-text-field > .v-input__control > .v-input__slot {
  cursor: default !important;
}
div#mileage > .v-text-field > .v-input__control > .v-input__slot > .v-text-field {
  cursor: text !important;
}
</style>
