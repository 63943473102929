import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '../router/router';

Vue.use(Vuex);

const base = process.env.VUE_APP_SERVER;

export default new Vuex.Store({
  state: {
    drawer: false,
    initialLoad: true,
    jobListings: [],
    categoryOptions: [],
    availableLocations: [],
    keywords: [],
    filteredJobListings: [],
    gmapsJobIds: [],
    filters: {
      categories: [],
      gmaps: {
        distance: null,
        duration: null,
        departure: null,
        origin: null,
        ids: null,
        avoid: null,
        mode: 0,
        zip: null,
      },
      locations: [],
      keywords: null,
    },
    findJobLoader: false,
    geoLocation: false,
    cookieConsentVisibility: true,
    jobAlert: {},
    jobAlertTrue: false,
    subscriptionId: '',
    subscribedCategories: [],
    subscriptionInfo: {},
    unsubscribedStatus: false,
    hideNavJobAlert: false,
    successText: 'Success!',
    resetAll: false,
  },
  mutations: {
    updateDrawerBoolValue(state, value) {
      state.drawer = value;
    },
    resetSelectedCategories(state, value) {
      state.filters.categories = value;
    },
    resetSelectedGmapsOptions(state) {
      state.filters.gmaps = {
        gmaps: {
          distance: null,
          duration: null,
          departure: null,
          origin: null,
          ids: null,
          avoid: null,
          mode: 0,
        },
      };
    },
    setResetAll(state, value) {
      state.resetAll = value;
    },
    setSuccessText(state, value) {
      state.successText = value;
    },
    setHideNavJobAlert(state, value) {
      state.hideNavJobAlert = value;
    },
    setUnsubscribedStatus(state, value) {
      state.unsubscribedStatus = value;
    },
    setJobAlertSubscriptionId(state, value) {
      state.subscriptionId = value;
    },
    setJobAlertCategories(state, value) {
      state.jobAlert.categories = value;
    },
    jobAlertCategories(state, value) {
      state.subscriptionInfo = value;
      state.subscribedCategories = value.categories;
    },
    setJobAlertSuccess(state, value) {
      state.jobAlertTrue = value;
    },
    updateSubscriptionInfoObject(state, value) {
      state.subscriptionInfo.name = state.jobAlert.name;
      state.subscriptionInfo.categories = value;
    },
    updateJobAlertObject(state, value) {
      state.jobAlert = value;
    },
    updateCookieConsentVisibility(state, value) {
      state.cookieConsentVisibility = value;
    },
    updateFindJobLoader(state, value) {
      state.findJobLoader = value;
    },
    addJobListings(state, listing) {
      state.jobListings = listing;
      state.initialLoad = false;
    },
    addCategoryOptions(state, options) {
      state.categoryOptions = options;
      state.filters.categories = options.map((category) => category.name);
    },
    updateAvailableLocations(state, locations) {
      state.availableLocations = locations;
    },
    updateFilteredJobListings(state, listing) {
      state.filteredJobListings = listing;
    },
    updateKeywords(state, keywords) {
      state.keywords = keywords;
    },
    updateFiltersWithSelectedCategory(state, category) {
      state.filters.categories = category;
    },
    updateFiltersWithSelectedLocations(state, locations) {
      if (locations.length > 0) {
        state.filters.locations = locations;
      } else {
        state.filters.locations = [];
      }
    },
    updateGmaps(state, gmaps) {
      state.filters.gmaps = gmaps;
      state.geoLocation = true;
    },
    updateGmapsOrigin(state, origin) {
      state.filters.gmaps.origin = origin;
    },
    updateGmapsJobIds(state, ids) {
      state.gmapsJobIds = ids;
    },
    updateJobListingsFromFilters(state) {
      const jobs = state.jobListings;
      // Filter by category
      const { categories } = state.filters;
      let filteredJobs = jobs.filter((job) => categories.includes(job.category));
      // Filter by location
      if (state.filters.locations.length > 0) {
        const { locations } = state.filters;
        filteredJobs = filteredJobs.filter((job) => locations.includes(job.location));
      }
      // Filter by mileage
      // Filter by keywords
      if (state.keywords.length > 0) {
        // eslint-disable-next-line max-len
        filteredJobs = filteredJobs.filter((job) => state.keywords.some((term) => job.description.replace(/<[^>]*>?/gm, '').toLowerCase().includes(` ${term.toLowerCase()} `)));
      }

      state.filteredJobListings = filteredJobs;
    },
  },
  actions: {
    createJobAlert({ commit, state }) {
      const config = {
        headers: { 'Content-Type': 'application/json' },
        crossDomain: true,
      };
      return axios.post(`${base}/alerts/CreateJobAlert`, state.jobAlert, config)
        .then((result) => {
          if (result.status === 201) {
            commit('setJobAlertSuccess', true);
          }
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 409) {
              state.subscriptionInfo.name = state.jobAlert.name;
              router.push(`/unsubscribe/${error.response.data.guid}`);
            } else {
              router.push('/uhoh');
            }
          }
        });
    },
    updateJobAlertCategories({ commit, state }) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        crossDomain: true,
      };
      console.log(state.subscriptionInfo);
      return axios.put(`${base}/alerts/UpdateJobAlert?guid=${state.subscriptionId}`, state.subscriptionInfo, config)
        .then((result) => {
          if (result.status === 204) {
            commit('setSuccessText', "You're changes have been updated");
            router.push('/success');
          }
        }).catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              router.push('/uhoh');
            }
          }
        });
    },
    getJobListings({ commit }) {
      return axios.get(`${base}/jobs/GetAllJobListings`)
        .then((result) => {
          commit('addJobListings', result.data);
        })
        .catch(console.error);
    },
    getCategoryOptions({ commit }) {
      return axios.get(`${base}/jobs/GetCategoryOptions`)
        .then((result) => {
          commit('addCategoryOptions', result.data);
        })
        .catch(console.error);
    },
    filterByMileage({ commit, state }) {
      const ids = state.filteredJobListings.map((job) => job.id);
      const endpoint = 'jobs/GetJobsByDistance';

      const urlWithParams = new URL(`${base}/${endpoint}`);
      let origin = '';
      if (typeof state.filters.gmaps.origin === 'object') {
        origin = `${state.filters.gmaps.origin.lat},${state.filters.gmaps.origin.lng}`;
      }
      origin = state.filters.gmaps.origin;
      urlWithParams.searchParams.append('origin', origin);
      urlWithParams.searchParams.append('ids', ids);

      if (state.filters.gmaps.distance != null) {
        urlWithParams.searchParams.append('distance', state.filters.gmaps.distance);
      }
      if (state.filters.gmaps.duration != null) {
        urlWithParams.searchParams.append('duration', state.filters.gmaps.duration);
      }
      if (state.filters.gmaps.departure != null) {
        urlWithParams.searchParams.append('departure', state.filters.gmaps.departure);
      }

      switch (state.filters.gmaps.avoid) {
        case 0:
          urlWithParams.searchParams.append('avoid', 'tolls');
          break;
        case 1:
          urlWithParams.searchParams.append('avoid', 'highways');
          break;
        case 2:
          urlWithParams.searchParams.append('avoid', 'ferries');
          break;
        default:
          break;
      }

      switch (state.filters.gmaps.mode) {
        case 0:
          urlWithParams.searchParams.append('mode', 'driving');
          break;
        case 1:
          urlWithParams.searchParams.append('mode', 'bicycling');
          break;
        case 2:
          urlWithParams.searchParams.append('mode', 'walking');
          break;
        default:
          break;
      }

      return axios.get(urlWithParams.href)
        .then((result) => {
          commit('updateFilteredJobListings', result.data);
          commit('updateFindJobLoader', false);
        })
        .catch(console.error);
    },
    getJobAlertSubscription({ commit, state }) {
      const urlWithParams = new URL(`${base}/alerts/GetSubscription`);
      urlWithParams.searchParams.append('guid', state.subscriptionId);
      return axios.get(urlWithParams.href).then((result) => {
        commit('jobAlertCategories', result.data);
      });
    },
    unsubscribeFromJobAlerts({ commit, state }) {
      let type = 'phone';
      if (state.subscriptionInfo.email != null) {
        type = 'email';
      }

      const urlWithParams = new URL(`${base}/alerts/Unsubscribe`);
      urlWithParams.searchParams.append('guid', state.subscriptionId);
      urlWithParams.searchParams.append('type', type);

      return axios.delete(urlWithParams.href).then((result) => {
        if (result.status === 204) {
          commit('setUnsubscribedStatus', true);
        }
      });
    },
    validateSubscription({ commit, state }) {
      const urlWithParams = new URL(`${base}/alerts/Validate`);
      urlWithParams.searchParams.append('guid', state.subscriptionId);
      return axios.get(urlWithParams.href).then((result) => {
        if (result.status === 204) {
          commit('setSuccessText', "You're subscribed!");
          router.push('/success');
        }
      }).catch((error) => {
        if (error.response) {
          router.push('/uhoh');
        } else {
          console.error(error);
        }
      });
    },
  },
});
