<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <div :id="stickyOrNot">
    <v-card
      class="resume-html sticky overflow-auto"
      elevation="1"
      :id="JobId"
      max-height="95vh"
    >
      <v-toolbar
        flat
        color="transparent"
      >
        <v-spacer></v-spacer>
        <v-btn
          class="jobClose"
          icon
          @click="hideJobInfo"
          elevation="1"
          color="white"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text
        v-html="getDescription"
        style="margin-top: -70px; color: black;"
      >
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          dark
          rounded
          :href="getApplyLink"
          target="_blank"
        >
          Apply Now
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <!-- <script v-html="jsonld" type="application/ld+json"></script> -->
  </div>
</template>

<script>

export default {
  name: 'JobInfo',
  props: {
    JobId: Number,
  },
  mounted() {
    const breakpoint = this.$vuetify.breakpoint.name;
    if (breakpoint === 'xs') {
      document.getElementById(this.JobId).scrollIntoView(true);
    }
    // Wait until all elements have mounted
    this.$nextTick(() => {
      this.scrollToJobListing(this.JobId);
      this.generateJson();
      document.title = this.job.title;
    });
  },
  updated() {
    // Wait until all elements have finished updating
    this.$nextTick(() => {
      this.scrollToJobListing(this.JobId);
      this.generateJson();
      document.title = this.job.title;
    });
  },
  data() {
    return {
      jobDescription: null,
      job: null,
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    listings() {
      return this.$store.state.jobListings;
    },
    getDescription() {
      const job = this.getJobDescription();
      return job.description;
    },
    getApplyLink() {
      const title = encodeURIComponent(this.job.title);
      return `https://apply.landrumjobs.com/Jobs/JobApply/${this.JobId}/${title}`;
    },
    stickyOrNot() {
      if (this.breakpoint === 'xs') {
        return '';
      }
      return 'jobInfo';
    },
  },
  methods: {
    hideJobInfo() {
      this.$emit('hideJobInfo');
      this.$router.push({ name: 'Home' });
    },
    getJobDescription() {
      const job = this.listings.filter((obj) => obj.id === this.JobId).pop();
      if (job === undefined) {
        this.$router.push({ name: 'NotFound' });
        return '';
      }
      this.job = job;
      return job;
    },
    scrollToJobListing(id) {
      const selectedCard = document.getElementById(id);
      this.$vuetify.goTo(selectedCard, { duration: 500 });
    },
    generateJson() {
      const ld = document.getElementById('job_script');
      const seo = {
        '@context': 'https://schema.org/',
        '@type': 'JobPosting',
        title: this.job.title,
        description: this.job.description,
        datePosted: this.job.lastPublished,
        hiringOrganization: {
          '@type': 'Organization',
          name: 'LandrumHR',
          sameAs: 'https://www.landrumhr.com/',
          logo: 'https://landrumjobs.com/logo_bubble.png',
        },
        jobLocation: {
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            addressLocality: this.job.city,
            addressRegion: this.job.state,
            postalCode: this.job.zipCode,
            addressCountry: 'US',
          },
        },
        occupationalCategory: this.job.category,
        employmentType: this.job.type,
      };
      ld.innerHTML = JSON.stringify(seo);
    },
  },
};
</script>

<style lang="scss" scoped>
#jobInfo {
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 0;
}
header > div.v-toolbar__content > button.jobClose.v-btn {
  background-color: black;
  opacity: 0.25;
}
header > div.v-toolbar__content > button.jobClose.v-btn:hover {
  opacity: 1;
}
</style>
