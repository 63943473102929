<template>
  <v-card
    class="ma-auto"
    max-width="600px"
    rounded="xl"
    elevation="4"
  >
    <v-card-title>Category Options</v-card-title>
    <v-card-text>
      <div class="px-1">
        <v-checkbox
          v-model="tempSelectAll"
          label="Select All"
          on-icon="mdi-check-circle"
          off-icon="mdi-checkbox-blank-circle-outline"
          @click="selectAllOptions"
        ></v-checkbox>
      </div>
      <v-divider
        class="mx-2"
        role="presentation"
      >
      </v-divider>
      <v-row class="mt-0">
        <v-col
          sm="6"
          cols="12"
          v-for="(item, index) in options"
          :key="index"
          class="pa-0"
        >
          <v-list
            flat
            class="py-0"
          >
            <v-list-item-group
              multiple
              v-model="tempSelectedOptions"
            >
              <v-list-item
                :key="`item-${index}`"
                :value="item.name"
                color="primary"
                @click="tempSelectAll = false"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action
                    class="my-0 mr-4"
                  >
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        class="blue--text text--darken-2 font-weight-bold mr-0"
        color="white"
        @click="apply"
      >
        Apply
      </v-btn>
      <v-btn
        depressed
        class="blue--text text--darken-2 font-weight-bold mr-4"
        color="white"
        @click="cancel"
      >
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'CategoryOptions',
  data() {
    return {
      selectAll: true,
      tempSelectAll: true,
      tempSelectedOptions: [],
    };
  },
  watch: {
    selectedOptions() {
      this.setSelectAllState();
      this.tempSelectedOptions = this.$store.state.filters.categories;
    },
  },
  computed: {
    options() {
      return this.$store.state.categoryOptions;
    },
    selectedOptions: {
      get() {
        return this.$store.state.filters.categories;
      },
      set(newValue) {
        this.$store.commit('updateFiltersWithSelectedCategory', newValue);
      },
    },
  },
  mounted() {
    this.tempSelectedOptions = this.selectedOptions;
    this.setSelectAllState();
  },
  methods: {
    apply() {
      this.selectedOptions = this.tempSelectedOptions;
      this.selectAll = this.tempSelectAll;
      this.$store.commit('updateFiltersWithSelectedCategory', this.selectedOptions);
      this.$emit('hideCategoryMenu');
    },
    cancel() {
      this.tempSelectedOptions = this.selectedOptions;
      this.tempSelectAll = this.selectAll;
      this.$emit('hideCategoryMenu');
    },
    selectAllOptions() {
      if (!this.tempSelectAll) {
        this.tempSelectedOptions = [];
      } else {
        this.tempSelectedOptions = this.options.map((category) => category.name);
      }
    },
    setSelectAllState() {
      const selectedCategories = this.$store.state.filters.categories;
      const categories = this.$store.state.categoryOptions;

      if (selectedCategories.length === categories.length) {
        this.tempSelectAll = true;
        this.selectAll = true;
      } else {
        this.tempSelectAll = false;
        this.selectAll = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__actions {
  background-color: #3B63FF;
}
.theme--light.v-divider {
  border-color: rgba(0, 0, 0, 0.274);
}
.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
  padding: 2px 0;
}
</style>
