<template>
  <div>
    <HeaderBar />
    <v-main>
      <FiltersBar />
      <v-container>
        <!-- <v-speed-dial
          transition="slide-y-reverse-transition"
          bottom
          right
          fixed
          :class="{ 'pulse': isPulse }"
          v-if="alerts == true && breakpoint != 'xs'"
        >
          <template v-slot:activator>
            <v-btn
              fab
              color="#D50000"
              dark
              @click="isPulse = false"
            >
              <v-icon>mdi-bell-ring</v-icon>
            </v-btn>
          </template>
          <v-tooltip
            v-model="signupTooltipVisibility"
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="#15a6b6"
                v-bind="attrs"
                v-on="on"
                href="/signup"
                target="_blank"
                @click="alerts = false"
              >
                <v-icon>mdi-email-newsletter</v-icon>
              </v-btn>
            </template>
            <span>Signup for job alerts</span>
          </v-tooltip>
          <v-tooltip
            v-model="hideToolTipVisibility"
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="#15a6b6"
                v-bind="attrs"
                v-on="on"
                @click="alerts = false"
              >
                <v-icon>mdi-eye-off</v-icon>
              </v-btn>
            </template>
            <span>Hide alerts</span>
          </v-tooltip>
        </v-speed-dial> -->
        <v-row>
          <v-col
            class="ma-0"
            :xl=listings.xl
            :lg=listings.lg
            :md=listings.md
            :sm=listings.sm
            v-if="jobListingVisibility"
          >
            <JobListings
              @showJob="(jobId) => openJobId(jobId)"
              :InfoVisibility=jobInfoVisibility
            />
          </v-col>
          <v-col
            class="ma-0"
            :xl=info.xl
            :lg=info.lg
            :md=info.md
            :sm=info.sm
            v-if="jobInfoVisibility"
            v-resize.quiet="hideJobInfo"
          >
            <JobInfo
              :JobId="jobInfoId"
              @hideJobInfo="hideJobInfo"
              v-if="jobInfoVisibility"
            />
          </v-col>
          <v-col
            class="ma-0"
            :xl=map.xl
            v-if="isXl"
          >
            <GoogleMap />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-bottom-sheet
      v-model="cookieConsent"
      persistent
      :inset="isLargerThanSM"
    >
      <v-card
        flat
      >
        <v-card-title>This website uses cookies</v-card-title>
        <v-divider class="mx-4 mb-2"></v-divider>
        <v-card-text>
          <!-- eslint-disable-next-line max-len -->
          <p>Cookies are important to the proper functioning of a site. To improve your experience, we use cookies to collect statistics in order to optimize site functionality. This allows us to improve underperforming job posts and to track unforseen errors.</p>
          <!-- eslint-disable-next-line max-len -->
          <p>Click "<span class="blue--text text--darken-4">Agree and proceed</span>" to accept these cookies and proceed to the site or click "<span class="blue--text text--darken-4">More Information</span>" to see detailed descriptions or to opt out.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            dark
            @click="agreeToCookieConsent"
          >Agree and proceed</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="blue"
            @click="moreInformation = true"
          >
            More Information
          </v-btn>
        </v-card-actions>
        <v-expand-transition>
          <v-card
            v-if="moreInformation"
            class="transition-fast-in-fast-out v-card--reveal"
          >
            <v-card-title>Cookie data we collect</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              <!-- eslint-disable-next-line max-len -->
              <p><strong>Which job posts you visit:</strong> This allows us to track and fix underperforming jobs and deliver better content.</p>
              <!-- eslint-disable-next-line max-len -->
              <p><strong>Browser and screen size:</strong> This allows us to improve the presentation of material for a variety of devices.</p>
              <!-- eslint-disable-next-line max-len -->
              <p><strong>Geolocation:</strong> We collect what city and state you are in. This is used to further improve job postings for our candidates.</p>
              <!-- eslint-disable-next-line max-len -->
              <p>By clicking "<span class="blue--text text--darken-4">Agree and proceed</span>" you accept these cookies and proceed to the site. Clicking "<span class="blue--text text--darken-4">Deny cookies</span>" will allow you to proceed to the site but some functionality may be impaired.</p>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="agreeToCookieConsent"
                dark
              >
                Agree and proceed
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                @click="denyToCookieConsent"
                text
                color="blue"
              >
                Deny Cookies
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expand-transition>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import HeaderBar from './HeaderBar.vue';
import FiltersBar from './FiltersBar.vue';
import JobListings from './JobListings.vue';
import GoogleMap from './GoogleMap.vue';
import JobInfo from './JobInfo.vue';

import jobs from '../data/jobs.json';
import categories from '../data/categories.json';

export default {
  name: 'HomePage',
  components: {
    HeaderBar,
    FiltersBar,
    JobListings,
    GoogleMap,
    JobInfo,
  },
  data() {
    return {
      moreInformation: null,
      jobInfoVisibility: false,
      jobListingVisibility: true,
      jobInfoId: null,
      listings: {
        xl: 7,
        lg: 12,
        md: 12,
        sm: 12,
      },
      info: {
        xl: 5,
        lg: 7,
        md: 7,
        sm: 7,
      },
      map: {
        xl: 5,
      },
      jobListings: jobs,
      options: categories,
      // isPulse: true,
      alerts: true,
      hideToolTipVisibility: false,
      signupTooltipVisibility: false,
    };
  },
  watch: {
    breakpoint() {
      if (this.breakpoint !== 'lg' || this.breakpoint !== 'xl') {
        this.hideToolTipVisibility = true;
        this.signupTooltipVisibility = true;
      }
    },
    $route(to, from) {
      if (from.name === 'Home' && to.name === 'JobInfo') {
        this.jobInfoVisibility = true;
      }
      if (to.name === 'Home' && from.name === 'JobInfo') {
        this.jobInfoVisibility = false;
      }
      if (this.jobInfoVisibility) {
        this.jobInfoId = Number.parseInt(to.params.job_id, 10);
        this.openJobId(this.jobInfoId);
      }
    },
    jobInfoVisibility() {
      if (this.jobInfoVisibility) {
        this.listings.xl = 3;
        this.listings.lg = 5;
        this.listings.md = 5;
        this.listings.sm = 5;
        this.map.xl = 4;

        // Hide jobslist conditionally on xs breakpoints
        if (this.breakpoint === 'xs') {
          this.jobListingVisibility = false;
        }
      } else {
        this.listings.xl = 7;
        this.listings.lg = 12;
        this.listings.md = 12;
        this.listings.sm = 12;
        this.map.xl = 5;

        // Hide jobslist conditionally on xs breakpoints
        if (this.breakpoint === 'xs') {
          this.jobListingVisibility = true;
        }
      }
    },
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    isXl() {
      if (this.breakpoint === 'xl') {
        return true;
      }

      return false;
    },
    isLargerThanSM() {
      if (this.breakpoint === 'lg' || this.breakpoint === 'xl') {
        return true;
      }

      return false;
    },
    geoLocationEnabled() {
      if (this.$store.state.geoLocation) {
        if (this.$store.state.filters.gmaps.origin !== null) {
          return true;
        }
      }
      return false;
    },
    cookieConsent() {
      return this.$store.state.cookieConsentVisibility;
    },
  },
  created() {
    if (localStorage.expDate) {
      let expDate = new Date(localStorage.expDate);
      const { lapsedDays } = localStorage;
      let today = new Date();
      expDate.setDate(expDate.getDate() + parseInt(lapsedDays, 10));

      expDate = expDate.getTime() / 1000;
      today = today.getTime() / 1000;

      if (expDate > today) {
        this.$store.commit('updateCookieConsentVisibility', false);
      } else {
        localStorage.removeItem('expDate');
      }
    }
  },
  mounted() {
    document.title = 'LandrumHR Jobs';
    this.$store.dispatch('getJobListings').then(() => {
      const locations = this.$store.state.jobListings.map((obj) => `${obj.location}`);
      const uniqLocations = [...new Set(locations)].sort();
      this.$store.commit('updateAvailableLocations', uniqLocations);

      if (this.$store.state.filteredJobListings.length < 1) {
        this.$store.commit('updateFilteredJobListings', this.$store.state.jobListings);
      }

      // dispatch is async, so don't load a job until after getJobListings
      if (this.$route.params.job_id) {
        this.jobInfoId = Number.parseInt(this.$route.params.job_id, 10);
        this.openJobId(this.jobInfoId);
      }

      if (this.$route.query.keywords !== undefined) {
        this.$store.commit('updateKeywords', this.$route.query.keywords.split(' '));
      }

      if (this.$route.query.location !== undefined) {
        this.$store.commit('updateFiltersWithSelectedLocations', [this.$route.query.location]);
      }

      if (this.$route.query.location !== undefined || this.$route.query.keywords !== undefined) {
        this.$store.commit('updateJobListingsFromFilters');
      }
    });

    this.$store.dispatch('getCategoryOptions');
  },
  methods: {
    openJobId(jobId) {
      this.jobInfoId = jobId;
      this.jobInfoVisibility = true;
    },
    hideJobInfo() {
      if (this.breakpoint === 'lg' || this.breakpoint === 'xl') {
        this.jobInfoVisibility = false;
        document.title = 'LandrumHR Jobs';
      }
    },
    getJobListings() {
      this.$store.commit('addJobListings', this.jobListings);
    },
    getCategoryOptions() {
      this.$store.commit('addCategoryOptions', this.options);
    },
    agreeToCookieConsent() {
      this.addConsentExpToLocalStorage();
      this.$store.commit('updateCookieConsentVisibility', false);
      this.$matomo.rememberConsentGiven();
      this.$matomo.rememberCookieConsentGiven();
    },
    denyToCookieConsent() {
      this.addConsentExpToLocalStorage();
      this.$store.commit('updateCookieConsentVisibility', false);
    },
    addConsentExpToLocalStorage() {
      localStorage.expDate = new Date().toISOString().slice(0, 10);
      localStorage.lapsedDays = process.env.VUE_APP_CONSENT_EXP;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
// .pulse {
//   background: #D50000;
//   border-radius: 50%;
//   box-shadow: 0 0 0 0 #D50000;
//   transform: scale(1);
//   animation: pulse-black 1s infinite;
// }
// @keyframes pulse-black {
//   0% {
//     transform: scale(0.95);
//     box-shadow: 0 0 0 0 #D50000b3;
//   }
//   70% {
//     transform: scale(1);
//     box-shadow: 0 0 0 10px rgba(0, 230, 118, 0);
//   }
//   100% {
//     transform: scale(0.95);
//     box-shadow: 0 0 0 0 rgba(0, 230, 118, 0);
//   }
// }
</style>
