<template>
  <v-main>
    <HeaderBar />
    <v-container id="categories">
      <v-row class="mt-16" align="center" justify="center">
        <v-col
          xl="8"
          lg="8"
          cols="12"
        >
          <v-card id="subscriptionOptions">
            <v-card-title
              class="text-lg-h5 text-h5 justify-center text-center"
              style="word-break: break-word; background-color: lightgrey;"
            >
              Would you like to change your subscribed categories?
            </v-card-title>
            <v-card-text class="mt-3">
              <v-row>
                <v-col
                  v-for="(item, index) in options"
                  :key="index"
                  xl="6"
                  lg="6"
                  md="6"
                  sm="6"
                  cols="12"
                >
                  <v-checkbox
                    class="checkegories"
                    v-model="categorySelections"
                    :label="item.name"
                    :value="item.name"
                    hide-details
                    dense
                    color="info"
                    style="padding-left: 6px;"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <!-- <v-card-actions class="mt-8">
              <v-spacer></v-spacer>
              <v-btn
                color="#3bc5ff"
                depressed
                @click="updateCategories"
              >
                Update
              </v-btn>
            </v-card-actions> -->
          </v-card>
          <v-card
            class="mt-5"
          >
            <v-card-title
              class="text-lg-h5 text-h5 justify-center text-center"
              style="word-break: break-word; background-color: lightgrey;"
            >
              Unsubscribe from LandrumHR Job Alerts
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-checkbox
                class="checkegories"
                color="info"
                v-model="optOut"
                label="Opt out of all LandrumHR Job Alerts"
              ></v-checkbox>
            </v-card-actions>
          </v-card>
          <div class="mt-5 text-center">
            <v-btn
              class="white--text"
              color="#3b63ff"
              depressed
              @click="updatePreferences"
            >
              Update Preferences
            </v-btn>
          </div>
          <!-- <div class="mt-5 text-center">
            <div>If you would like to remove yourself from future job alerts please</div>

          </div> -->
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import HeaderBar from './HeaderBar.vue';

export default {
  name: 'UnsubscribeUser',
  components: {
    HeaderBar,
  },
  data() {
    return {
      categorySelections: [],
      optOut: false,
    };
  },
  computed: {
    options() {
      return this.$store.state.categoryOptions;
    },
    subscriptionId() {
      return this.$route.params.subscriptionId;
    },
    subscribedCategories() {
      return this.$store.state.subscribedCategories.map((cat) => (cat.categoryName));
    },
  },
  created() {
    document.title = 'Change Subscription Options';
    this.$store.commit('setJobAlertSubscriptionId', this.subscriptionId);
    this.$store.dispatch('getCategoryOptions').then(() => {
      this.$store.dispatch('getJobAlertSubscription').then(() => {
        this.categorySelections = this.subscribedCategories;
      });
    });
  },
  methods: {
    updatePreferences() {
      if (this.optOut) {
        this.unsubscribeFromJobAlerts();
        if (this.$store.state.unsubscribedStatus === true) {
          this.$store.commit('setSuccessText', "You've been unsubscribed");
          this.$router.push('/success');
        }
      }

      this.updateSubscription();
    },
    updateSubscription() {
      const categories = this.categorySelections.map((cat) => ({ categoryName: cat }));
      this.$store.commit('updateSubscriptionInfoObject', categories);
      this.$store.dispatch('updateJobAlertCategories');
    },
    unsubscribeFromJobAlerts() {
      this.$store.dispatch('unsubscribeFromJobAlerts');
    },
  },
};
</script>

<style lang="scss" scoped>
#categories {
  ::v-deep i.v-icon {
    font-size: 23px;
  }
}
</style>
