<template>
  <header>
    <v-app-bar
      flat
      color="white"
      v-if="breakpoint != 'xs'"
    >
      <a href="/">
        <img
          class="logo"
          src="../assets/logo_new.png"
          height="40"
          alt="LandrumHR.com"
        >
      </a>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <!-- eslint-disable-next-line max-len -->
      <a id="secondary" href="https://landrumworkforcesolutions.com/candidate-resources/get-hired-blog">Get Hired Blog</a>
      <v-spacer></v-spacer>
      <a id="secondary" href="https://landrumworkforcesolutions.com/candidate-contact">Contact</a>
      <v-spacer></v-spacer>
      <a id="secondary" href="https://landrumworkforcesolutions.com/candidate-resources">Candidate Resources</a>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-app-bar
      flat
      color="white"
      v-if="breakpoint === 'xs'"
    >
      <a href="/">
        <img
          class="logo"
          src="../assets/logo_new.png"
          height="40"
        >
      </a>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item href="https://landrumworkforcesolutions.com/candidate-resources/get-hired-blog">
          <v-list-item-icon>
            <v-icon>mdi-newspaper-variant-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Get Hired Blog</v-list-item-title>
        </v-list-item>
        <v-list-item href="https://landrumworkforcesolutions.com/candidate-contact">
          <v-list-item-icon>
            <v-icon>mdi-comment-question-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Contact</v-list-item-title>
        </v-list-item>
        <v-list-item href="https://landrumworkforcesolutions.com/candidate-resources">
          <v-list-item-icon>
            <v-icon>mdi-folder-text-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Candidate Resources</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-card
        elevation="0"
        v-if="hidden == false"
      >
        <v-card-text>
          <p class="text-h6 text-center">
            Signup for job alerts
          </p>
          <div class="text-center">
            <!-- eslint-disable-next-line max-len -->
            Get notified when new jobs are posted. We send daily alerts to your inbox and more frequent alerts via text messaging.
          </div>
          <br>
          <v-text-field
            v-model="jobAlert.name"
            ref="name"
            :rules="[rules.required]"
            label="Name"
            placeholder="John Doe"
          ></v-text-field>
          <v-text-field
            v-model="jobAlert.email"
            ref="email"
            :rules="[rules.email]"
            label="Email"
            placeholder="john.doe@gmail.com"
            suffix="optional"
          ></v-text-field>
          <v-text-field
            v-model="jobAlert.phone"
            ref="phone"
            :rules="[rules.phone]"
            label="Mobile Phone"
            placeholder="950-555-1212"
            suffix="optional"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#3b63ff"
            depressed
            class="white--text"
            @click="signup"
          >
            Sign Up!
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-navigation-drawer>
    <v-snackbar
      v-model="snackbar"
      timeout="5000"
      color="error"
      text
    >
      You must enter an <strong>email</strong>, <strong>phone</strong>, or <strong>both</strong>.
      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </header>
</template>

<script>
import { validationRules } from '@/mixins/validationRules';

export default {
  name: 'HeaderBar',
  mixins: [validationRules],
  data() {
    return {
      snackbar: false,
      jobAlert: {
        name: null,
        email: null,
        phone: null,
        emailEnabled: false,
        smsEnabled: false,
        categories: [],
      },
    };
  },
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    },
    hidden() {
      return this.$store.state.hideNavJobAlert;
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(newValue) {
        this.$store.commit('updateDrawerBoolValue', newValue);
      },
    },
  },
  methods: {
    signup() {
      if (!this.jobAlert.email && !this.jobAlert.phone) {
        this.snackbar = true;
        return;
      }

      if (!this.$refs.name.validate()) {
        this.$refs.name.validate(true);
        return;
      }

      if (!this.$refs.email.validate()) {
        this.$refs.email.validate(true);
        return;
      }

      if (!this.$refs.phone.validate()) {
        this.$refs.phone.validate(true);
        return;
      }

      if (this.jobAlert.phone != null) {
        this.jobAlert.phone = this.jobAlert.phone.replace(this.phoneRegex, '$1$2$3');
      }

      this.$store.commit('updateJobAlertObject', this.jobAlert);
      this.$store.commit('updateDrawerBoolValue', false);

      this.$router.push('/signup-mobile');
    },
  },
};
</script>

<style lang="scss" scoped>
#primary {
  color: #006FFF;
  text-decoration: none;
  font-weight: bold;
}
#secondary {
  color: #888888;
  text-decoration: none;
  font-weight: bold;
}
</style>
