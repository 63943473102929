<template>
  <div class="ma-0 sticky">
    <v-card
      elevation="1"
      max-height="95vh"
      width="100%"
      tile
    >
      <div id="map" ref="map">

      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'GoogleMap',
  data() {
    return {
      map: null,
      marker: null,
      mileageCircle: null,
      // default to pensacola
      mapCenter: {
        lat: 30.6545532,
        lng: -87.158514799999992,
      },
      infoWindow: null,
      locationButton: null,
      pos: {
        lat: null,
        lng: null,
      },
    };
  },
  watch: {
    // pos() {
    //   this.placeOrigin();
    //   if (this.distance === null) {
    //     this.placeMileageRadius();
    //   }
    // },
    address() {
      this.placeAddress();
    },
    distance() {
      this.placeMileageRadius();
    },
    gmap() {
      this.placeMileageOptions();
    },
  },
  computed: {
    // pos() {
    //   return this.$store.state.filters.gmaps.origin;
    // },
    address() {
      return this.$store.state.filters.gmaps.zip;
    },
    distance() {
      return this.$store.state.filters.gmaps.distance;
    },
    departure() {
      return this.$store.state.filters.gmaps.departure;
    },
    duration() {
      return this.$store.state.filters.gmaps.duration;
    },
    avoidances() {
      const { avoid } = this.$store.state.filters.gmaps;
      switch (avoid) {
        case 0:
          return 'tolls';
        case 1:
          return 'highways';
        case 2:
          return 'ferries';
        default:
          return '';
      }
    },
    mode() {
      const { mode } = this.$store.state.filters.gmaps;
      switch (mode) {
        case 0:
          return 'driving';
        case 1:
          return 'bicycling';
        case 2:
          return 'walking';
        default:
          return '';
      }
    },
    gmap() {
      return this.$store.state.filters.gmaps;
    },
  },
  mounted() {
    this.checkAndAttachMapScript(this.initMap);
  },

  methods: {
    checkAndAttachMapScript(callback) {
      const scriptId = 'map-api-script';
      const mapAlreadyAttached = !!document.getElementById(scriptId);

      if (mapAlreadyAttached) {
        if (window.google) {
          callback();
        }
      } else {
        window.mapApiInitialized = callback;

        const script = document.createElement('script');
        script.id = scriptId;
        // eslint-disable-next-line max-len
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB-XDMqxSMtQ1DicALyAPgx6vQ8_O541i0&callback=mapApiInitialized';
        document.body.appendChild(script);
      }

      return mapAlreadyAttached;
    },

    initMap() {
      this.map = new window.google.maps.Map(document.getElementById('map'), {
        center: this.mapCenter,
        zoom: 10,
        maxZoon: 20,
        minZoom: 3,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: true,
        zoomControl: true,
      });
    },

    placeOrigin() {
      if (this.infoWindow !== null) {
        this.marker.setMap(null);
        this.marker = null;
      }
      this.infoWindow = new window.google.maps.InfoWindow();
      this.infoWindow.setContent('Distance and time are approximations');

      this.marker = new window.google.maps.Marker({
        position: this.pos,
        map: this.map,
        animation: window.google.maps.Animation.DROP,
      });

      this.marker.addListener('click', () => {
        this.infoWindow.open({
          anchor: this.marker,
          map: this.map,
          shouldFocus: false,
        });
      });

      this.infoWindow.open({
        anchor: this.marker,
        map: this.map,
        shouldFocus: false,
      });

      this.map.setCenter(this.pos);
    },

    geocode(request) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: request }, (result, status) => {
        const response = JSON.parse(JSON.stringify(result, null, 2));
        if (status === 'OK') {
          const { location } = response[0].geometry;
          this.pos.lat = location.lat;
          this.pos.lng = location.lng;
          this.map.setCenter(location);

          this.placeMileageRadius();
          this.placeOrigin();
        }
      });
    },

    placeAddress() {
      if (this.address !== null) {
        this.geocode(this.address);
      }
    },

    placeMileageRadius() {
      if (this.mileageCircle != null) {
        this.mileageCircle.setMap(null);
      }
      if (this.distance === null) {
        return;
      }
      this.mileageCircle = new window.google.maps.Circle({
        strokeColor: 'FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'FF0000',
        fillOpacity: 0.35,
        map: this.map,
        center: {
          lat: this.pos.lat,
          lng: this.pos.lng,
        },
        radius: this.distance * 1609.34,
        editable: false,
      });
    },

    placeMileageOptions() {
      this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].clear();
      const optionText = [];
      if (this.distance) {
        optionText.push(`Distance: ${this.distance} miles`);
      }
      if (this.departure) {
        optionText.push(`Leaving at: ${this.departure}`);
      }
      if (this.duration) {
        optionText.push(`Travel Time: ${this.duration} minutes`);
      }
      if (this.avoidances) {
        optionText.push(`Avoiding: ${this.avoidances}`);
      }
      if (this.mode) {
        if (optionText.length > 0) {
          optionText.push(`Transportation Type: ${this.mode}`);
        } else {
          return;
        }
      }

      const outerBoxUI = document.createElement('div');
      outerBoxUI.style.backgroundColor = '#fff';
      outerBoxUI.style.border = '2px solid #fff';
      outerBoxUI.style.borderRadius = '3px';
      outerBoxUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      outerBoxUI.style.margin = '10px';
      // outerBoxUI.style.textAlign = 'center';
      outerBoxUI.title = 'Mileage options';

      const boxText = document.createElement('div');
      boxText.style.fontFamily = 'Roboto,Arial,sans-serif';
      boxText.style.fontSize = '16px';
      boxText.style.lineHeight = '38px';
      boxText.style.paddingLeft = '5px';
      boxText.style.paddingRight = '5px';
      boxText.innerHTML = optionText.join('<br>');

      outerBoxUI.appendChild(boxText);

      this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(outerBoxUI);
    },

    handleLocationError(browserHasGeolocation, infoWindow, pos) {
      infoWindow.setPosition(pos);
      infoWindow.setContent(browserHasGeolocation
        ? 'Error: The Geolocation service failed.'
        : 'Error: Your browser doesn\'t support geolocation.');
      infoWindow.open(this.map);
    },
  },
};
</script>

<style scoped>
#map {
  height: calc(100vh - 236px);
  width: 100%;
  background: grey;
}
div.sticky {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0;
}
</style>
