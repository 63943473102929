<template>
  <v-main>
    <HeaderBar />
    <v-row class="mt-16" justify="center">
      <div class="text-center lottie">
        <!-- eslint-disable-next-line max-len -->
        <lottie-player src="./uhoh.json"  background="transparent"  speed="1.5" loop  autoplay></lottie-player>
        <div style="text-align: center;">Something went wrong</div>
        <div>Please contact <a href="https://www.landrumhr.com/contact-us">support</a> or try again later</div>
      </div>
    </v-row>
  </v-main>
</template>

<script>
import HeaderBar from './HeaderBar.vue';

export default {
  name: 'UhOh',
  components: {
    HeaderBar,
  },
  data() {
    return {
      temp: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.lottie {
  width: 90vw;
  max-width: 750px;
}
</style>
