<template>
  <v-main>
    <HeaderBar />
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title>Sorry</v-card-title>
        <v-card-text>
          <!-- eslint-disable-next-line max-len -->
          <div>You must select at least 1 category</div>
        </v-card-text>
        <v-card-actions style="background-color: #3B63FF;">
          <v-spacer></v-spacer>
          <v-btn
            class="blue--text text--darken-2 font-weight-bold mr-0"
            color="white"
            @click="dialog = false"
          >Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="successful">
      <!-- eslint-disable-next-line max-len -->
      <lottie-player class="lottie" src="./success.json" background="transparent" speed="2"></lottie-player>
      <h2 style="text-align: center; font-size: 3em;">Please check your messages</h2>
    </div>
    <v-container id="categories">
      <v-row class="mt-sm-16 mt-2" align="center" justify="center">
        <v-card
          class="cat"
          color="white"
          elevation="1"
          width="600px"
          style="padding: 2em;"
        >
          <v-card-text class="cSeq">
            <p class="text-h5 text-center font-weight-bold">You're almost done!</p>
            <p class="text-center">Please choose the categories you would like to be alerted for:</p>
            <v-row>
              <v-col
                v-for="(item, index) in options"
                :key="index"
                md="6"
                sm="6"
                cols="6"
              >
                <v-checkbox
                  class="checkegories"
                  v-model="categorySelections"
                  :label="item.name"
                  :value="item.name"
                  hide-details
                  dense
                  color="info"
                  style="padding-left: 6px;"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-8">
            <v-spacer></v-spacer>
            <v-btn
              color="#3b63ff"
              depressed
              class="white--text"
              :loading="showLoading"
              @click="submit"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import HeaderBar from './HeaderBar.vue';

export default {
  name: 'JobAlertsMobile',
  components: {
    HeaderBar,
  },
  data() {
    return {
      categorySelections: [],
      showLoading: false,
      dialog: false,
    };
  },
  computed: {
    jobAlert() {
      return this.$store.state.jobAlert;
    },
    options() {
      return this.$store.state.categoryOptions;
    },
    jobAlertTrue() {
      return this.$store.state.jobAlertTrue;
    },
  },
  created() {
    document.title = 'Subscribe to Job Alerts';
    this.$store.dispatch('getCategoryOptions');
  },
  methods: {
    submit() {
      if (this.categorySelections.length === 0) {
        this.dialog = true;
        return;
      }
      this.showLoading = true;
      const categories = this.categorySelections.map((cat) => ({ categoryName: cat }));
      this.$store.commit('setJobAlertCategories', categories);
      this.$store.dispatch('createJobAlert').then(() => {
        if (this.jobAlertTrue === true) {
          this.$matomo.trackEvent('Subscription', 'Alerts', 'Subscribed to job alerts!');
          gsap.registerPlugin(CustomEase);

          // eslint-disable-next-line new-cap
          const tl = new gsap.timeline({ defaults: { ease: 'circ.easeOut' } });
          // eslint-disable-next-line no-unused-vars
          const player = document.querySelector('.lottie');

          tl.paused(true);
          tl.to('.cSeq', { y: 40, opacity: 0, stagger: 0.05 });
          tl.to('.v-card.cat', { opacity: 0 }, '-=.4');
          tl.to('.successful', { opacity: 1, duration: 2, zIndex: 3 }, '+=1');

          tl.play();
          setTimeout(() => {
            player.play();
          }, 1000);
        } else {
          this.showLoading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#categories {
  ::v-deep i.v-icon {
    font-size: 23px;
  }
}
.successful {
  position: absolute;
  width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
</style>
