var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-6 mb-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"xl":"2","lg":"2","md":"4","sm":"4","cols":"12"}},[_c('v-sheet',{class:_vm.categoryClass,attrs:{"id":"categories","elevation":"1","rounded":_vm.isRounded}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({class:_vm.categoryHasSelectedOptions,attrs:{"label":"Categories","dense":"","single-line":"","solo":"","flat":"","hide-details":"true","readonly":""}},on),[_c('v-icon',{attrs:{"slot":"prepend","color":"#006FFF"},slot:"prepend"},[_vm._v(" mdi-briefcase-variant-outline ")])],1)]}}]),model:{value:(_vm.categoryMenu),callback:function ($$v) {_vm.categoryMenu=$$v},expression:"categoryMenu"}},[_c('CategoryOptions',{on:{"hideCategoryMenu":_vm.hideCategoryMenu}})],1),_c('v-overlay',{attrs:{"value":_vm.categoryMenu}})],1)],1),_c('v-col',{attrs:{"xl":"3","lg":"3","md":"8","sm":"8","cols":"12"}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"id":"locations","elevation":"1"}},[_c('v-select',{attrs:{"items":_vm.locations,"clearable":"","label":"Locations","flat":"","dense":"","hide-details":"","solo":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.selectedLocations.length < 2)?_c('span',{staticClass:"text-no-wrap text-caption overflow-hidden"},[_vm._v(" "+_vm._s(item)+" ")]):_vm._e(),(index === 1)?_c('span',{staticClass:"text-caption"},[_vm._v(" ("+_vm._s(_vm.selectedLocations.length)+" locations selected) ")]):_vm._e()]}}]),model:{value:(_vm.selectedLocations),callback:function ($$v) {_vm.selectedLocations=$$v},expression:"selectedLocations"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"#006FFF"},slot:"prepend"},[_vm._v(" mdi-map-marker-outline ")])],1)],1)],1),_c('v-col',{attrs:{"xl":"2","lg":"2","md":"4","sm":"4","cols":"12"}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"id":"mileage","elevation":"1"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({class:_vm.mileageHasSelectedOptions,attrs:{"label":"Mileage","dense":"","single-line":"","solo":"","flat":"","hide-details":"true","readonly":""}},on),[_c('v-icon',{attrs:{"slot":"prepend","color":"#006FFF"},slot:"prepend"},[_vm._v(" mdi-selection-marker ")])],1)]}}]),model:{value:(_vm.mileageMenu),callback:function ($$v) {_vm.mileageMenu=$$v},expression:"mileageMenu"}},[_c('MileageOptions',{on:{"hideMileageMenu":_vm.hideMileageMenu}})],1),_c('v-overlay',{attrs:{"value":_vm.mileageMenu}})],1)],1),_c('v-col',{attrs:{"xl":"4","lg":"4","md":"6","sm":"6","cols":"12"}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"elevation":"1"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Keywords","flat":"","dense":"","hide-details":"","solo":""},model:{value:(_vm.keywords),callback:function ($$v) {_vm.keywords=$$v},expression:"keywords"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"#006FFF"},slot:"prepend"},[_vm._v(" mdi-magnify ")])],1)],1)],1),_c('v-col',{attrs:{"xl":"1","lg":"1","md":"2","sm":"2","cols":"12"}},[_c('v-sheet',{class:_vm.findJobClass,attrs:{"elevation":"1","height":"100%","rounded":_vm.isRounded}},[_c('v-btn',{class:_vm.findJobClass,attrs:{"depressed":"","color":"#3B63FF","block":"","dark":"","height":"70px","tile":_vm.isTiled,"loading":_vm.loading},on:{"click":_vm.filterJobs}},[_vm._v(" Find Job ")])],1)],1)],1),_c('v-row',{staticClass:"mr-0 mt-n5"},[_c('v-spacer'),_c('div',{staticClass:"clearFilters mr-1"},[_c('span',{on:{"click":_vm.resetAllFilters}},[_c('u',[_vm._v("clear filters")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }