import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMatomo from 'vue-matomo';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router/router';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueMatomo, {
  host: 'https://matomo.landrumhr.com/',
  siteId: 4,
  router,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  requireConsent: true,
  requireCookieConsent: true,
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
